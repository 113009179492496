<template>
  <div class="map-wrapper">
    <div class="map-autocomplete-input">
      <gmap-autocomplete
          @place_changed="setPlace"
          class="input"
      ></gmap-autocomplete>
    </div>

    <GmapMap
        @click="getPosition"
        :center="center"
        :zoom="16"
        map-type-id="roadmap"
        style="height: 450px"
    >
      <gmap-info-window
          :options="{pixelOffset: {width: 0,height: -35}}"
          :position="infoWindowPos"
          :opened="infoWindowOpen"
          @closeclick="infoWindowOpen=false">
        <div class="has-text-weight-bold">{{ infoContent.header }}</div>
        <div>{{ infoContent.body }}</div>
      </gmap-info-window>

      <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click="center=m.position"
      />
    </GmapMap>
  </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vue from "vue";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDgoC3K5C4sJU9yrSkgy7Dt2MY3U4Ze1xM",
    libraries: "places,drawing"
  }
})

export default {
  name: "GoogleMapPicker",
  data: () => {
    return {
      markers: [],
      places: [],
      center: {lat: 0, lng: 0},
      currentPlace: null,
      infoContent: {
        header: null,
        body: null,
        footer: null
      },
      infoWindowPos: null,
      infoWindowOpen: null,
      markerMode: 'reset'
    }
  },
  props: {
    defaultCenter: {
      type: Object
    }
  },
  computed: {
    google: gmapApi
  },
  watch: {
    markers: {
      deep: true,
      handler(value) {
        if (value[0]) this.$emit('update-geolocation', value[0].position)
      }
    },
    defaultCenter: {
      deep: true,
      handler() {
        this.resetDefaultMarker()
      }
    }
  },
  created() {
    if (this.defaultCenter && this.defaultCenter.lat) this.resetDefaultMarker()
    else this.geolocate();
  },
  methods: {
    resetDefaultMarker() {
      this.currentPlace = null
      this.center = this._.cloneDeep(this.defaultCenter)
      this.addMarker()
    },

    setPlace(place) {
      this.currentPlace = place;
      this.addMarker();
    },

    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    },

    getPosition(marker) {
      this.geocodedAddress(
          marker,
          marker.latLng.lat(),
          marker.latLng.lng()
      );
    },

    geocodedAddress(map, lat, long) {
      var geocoder = new this.google.maps.Geocoder();

      var latlng = {
        lat: parseFloat(lat),
        lng: parseFloat(long)
      };

      geocoder.geocode({location: latlng}, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.currentPlace = results[0];
            this.addMarker();
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    },

    addMarker() {
      let place = this.currentPlace

      if (this.markerMode == "reset") {
        this.markers = [];
      }

      let marker = {}

      if (place && place.geometry)
        marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
      else if (this.defaultCenter)
        marker = this.defaultCenter

      this.markers.push({
        position: marker,
        infoText: this.currentPlace
      });

      this.center = marker;

      if (this.currentPlace) {
        this.places.push(this.currentPlace);

        this.toggleInfoWindow({
          position: marker,
          infoText: this.currentPlace
        });

        this.$emit("getMarkedData", {
          position: marker,
          infoText: this.currentPlace
        });
      }
    },

    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent.body = marker.infoText.formatted_address;
      this.infoWindowOpen = true;
      this.currentMidx = idx;
    },
  }
}
</script>

<style scoped>

.map-wrapper {
  position: relative;
}

.map-autocomplete-input {
  position: absolute;
  z-index: 10;
  left: 10px;
  margin: auto;
  bottom: 40px;
  max-width: 100%;
  width: 300px;
}

</style>
