<template>
  <div class="card" :class="{'is-shadowless': !isModal, 'on-modal': isModal}">
    <div class="card-content" :class="{'p-0': !isModal}">
      <header class="is-flex is-justify-content-space-between is-align-items-center mb-2" v-if="isModal">
        <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">Edit Farm</h2>
        <div @click="$emit('close')" v-if="isModal">
          <b-icon class="cursor-pointer" icon="close-thick"/>
        </div>
      </header>
      <ValidationObserver ref="farm-sign-up">
        <form class="py-4" autocomplete="off">
          <ValidationProvider :name="$t('farm.name')" rules="required" v-slot="{ errors }">
            <b-field :label="$t('farm.name')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
              <b-input type="text" v-model="farm.name" placeholder="Ex: Labangka"></b-input>
            </b-field>
          </ValidationProvider>
          <ValidationProvider :name="$t('city')" rules="required" v-slot="{ errors }">
            <b-field :label="$t('city')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
              <b-input type="text" v-model="farm.city" placeholder="Ex: Surabaya"></b-input>
            </b-field>
          </ValidationProvider>
          <ValidationProvider :name="$t('location')" rules="required" v-slot="{ errors }">
            <google-map-picker @update-geolocation="updateGeolocation"
                               :default-center="farm.location"/>
            <b-input type="hidden" v-model="farm.location.lat"></b-input>
            <p class="help is-danger" v-show="errors[0]">{{ errors[0] }}</p>
          </ValidationProvider>
        </form>
      </ValidationObserver>
      <footer class="has-text-right" v-if="isModal">
        <b-button type="is-secondary is-light" @click="$emit('close')">{{ $t('cancel') }}</b-button>
        <b-button type="is-success" class="ml-2" @click="updateFarm">Update</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import GoogleMapPicker from "@/components/GoogleMapPicker";

export default {
  name: "FarmSignUp",
  components: {
    GoogleMapPicker
  },
  props: {
    value: {
      type: Object,
    },
    isModal: {
      type: Boolean,
      default: false,
    }
  },
  data: () => {
    return {
      farm: {
        location: {}
      }
    }
  },
  watch: {
    farm: {
      deep: true,
      handler() {
        this.canContinue()
      }
    },
    value: {
      deep: true,
      handler() {
        this.resetFarmValue()
      }
    }
  },
  created() {
    if (this.value && this.value.location) {
      this.resetFarmValue()
    }
    this.canContinue()
  },
  methods: {
    resetFarmValue() {
      let location = this.value.location.split(',')
      this.farm = {
        name: this.value.name,
        city: this.value.city,
        color: this.value.color,
        created_at: this.value.created_at,
        uuid: this.value.uuid,
        location: {
          lat: parseFloat(location[0]),
          lng: parseFloat(location[1]),
        }
      }
    },

    updateGeolocation(value) {
      this.farm.location = value
    },

    async canContinue() {
      if (!this.isModal) {
        let status = false

        if (this.$refs['farm-sign-up'])
          await this.$refs['farm-sign-up'].validate().then((success) => {
            status = success
            this.$emit('update-data', {
              canContinue: success,
              data: this.farm
            })
          })

        return status
      }
    },

    async updateFarm() {
      this.$loading()

      let data = this._.cloneDeep(this.farm)
      data.location = data.location.lat + ',' + data.location.lng

      let res = await this.$store.dispatch('farm/updateFarm', {
        farm_id: this.value.farm_id,
        data: data
      })

      if (!res.error) {
        this.$emit('close')
        await this.$store.dispatch('farm/getFarm')
      }

      this.$loading(false)
    }
  },
}
</script>

<style scoped lang="scss">
.card {
  &.on-modal {
    width: 650px !important;
    max-width: 100%;
  }
}
</style>
